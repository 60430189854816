/* Global styles */
body {
  background: #f2f2f2;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Ensure the page content is restricted to mobile view */
html,
body {
  max-width: 768px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

/* Page container styling */
.page {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
}

/* Fonts */
@font-face {
  font-family: myFirstFont;
  src: url("/src/Fonts/Qanelas-Font-Family/woff2/QanelasSoft-Bold.woff2");
}
@font-face {
  font-family: mySecondFont;
  src: url("/src/Fonts/Qanelas-Font-Family/woff2/QanelasSoft-SemiBold.woff2");
}
@font-face {
  font-family: myThirdFont;
  src: url("/src/Fonts/Qanelas-Font-Family/woff2/QanelasSoft-Medium.woff2");
}
@font-face {
  font-family: myFourthFont;
  src: url("/src/Fonts/Qanelas-Font-Family/woff2/QanelasSoft-Light.woff2");
}
@font-face {
  font-family: myFifthFont;
  src: url("/src/Fonts/Qanelas-Font-Family/otf/QanelasSoft-Bold.otf");
}
@font-face {
  font-family: mySixthFont;
  src: url("Fonts/Qanelas-Font-Family/ttf/QanelasSoft-Black.ttf");
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* phone input */
.input-phone-number input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.input-phone-number input {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-family: mySecondFont;
}

/* Mobile-specific styles (if additional adjustments are needed) */
@media only screen and (max-width: 768px) {
  .page {
    /* Additional mobile-specific styles can be added here */
  }
}
